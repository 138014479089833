const Step = {
    The3dImageOfYourVessel: Symbol('3d_image_of_your_vessel'),
    //GiveAFeedback: Symbol('give_a_feedback'),
    ClinicalResultsOfYourVessel: Symbol('clinical_results_of_your_vessel'),
    ScoreOfYourVessel: Symbol('score_of_your_vessel'),
    // CommunityEvaluationOfYourVessel: Symbol('community_evaluation_of_your_vessel'),
    ExpertEvaluationOfYourVessel: Symbol('expert_evaluation_of_your_vessel'),
}

function hasValue(value) {
    return value !== null && value !== false
}

export function useVesselResult(vesselResultState) {
    const STEPS = new Map()

    initializeSteps()

    function initializeSteps() {
        STEPS.set(Step.The3dImageOfYourVessel, {
            id: Step.The3dImageOfYourVessel,
            title: '3D Image of your organ',
            route_name: 'vessel-results-id-3d-image-of-your-vessel',
            condition: (result) => hasValue(result.meshResult),
            hidden: false,
        })

        // STEPS.set(Step.GiveAFeedback, {
        //     id: Step.GiveAFeedback,
        //     title: 'Give a feedback',
        //     route_name: 'vessel-results-id-give-a-feedback',
        //     // Disable the functionality temporarily
        //     // condition: () => vesselResultState.vessels_for_review.length > 0,
        //     condition: () => false,
        //     hidden: true,
        // })

        STEPS.set(Step.ClinicalResultsOfYourVessel, {
            id: Step.ClinicalResultsOfYourVessel,
            title: 'Clinical results of your organ',
            route_name: 'vessel-results-id-clinical-results-of-your-vessel',
            condition: (result) => hasValue(result.clinicalResult),
            hidden: false,
        })

        STEPS.set(Step.ScoreOfYourVessel, {
            id: Step.ScoreOfYourVessel,
            title: 'Score of your organ',
            route_name: 'vessel-results-id-score-of-your-vessel',
            condition: (result) => hasValue(result.overallScore),
            hidden: false,
        })

        // STEPS.set(Step.CommunityEvaluationOfYourVessel, {
        //     id: Step.CommunityEvaluationOfYourVessel,
        //     title: 'Community evaluation of your organ',
        //     route_name: 'vessel-results-id-community-evaluation-of-your-vessel',
        //     condition: (result) => result.communityResponds.length > 0,
        //     hidden: false,
        // })

        STEPS.set(Step.ExpertEvaluationOfYourVessel, {
            id: Step.ExpertEvaluationOfYourVessel,
            title: 'Expert evaluation of your organ',
            route_name: 'vessel-results-id-expert-evaluation-of-your-vessel',
            condition: (result) => result.expertReviews.length > 0,
            hidden: false,
        })
    }

    function currentStepByRoute(vesselId, route) {
        const result = vesselResultState.result

        if (!result || parseInt(result.id) !== parseInt(vesselId)) {
            return null
        }

        const currentStep = [...STEPS.values()].find((each) => each.route_name === route)

        if (!currentStep) {
            return null
        }

        return currentStep
    }

    function nextStep(vesselId, currentStep) {
        const allStep = [...STEPS.values()]
        const currentIndex = allStep.findIndex((each) => each.id === currentStep)

        const next = allStep
            .find((step, index) => index > currentIndex && step.condition(vesselResultState.result.result))

        if (!next) {
            return { name: 'dashboard' }
        }

        return { name: next.route_name, params: { id: vesselId } }
    }

    function prevStep(vesselId, currentStep) {
        const allStep = [...STEPS.values()]
        const currentIndex = allStep.findIndex((each) => each.id === currentStep)
        const result = vesselResultState.result.result

        const prev = allStep.findLast((step, index) => index < currentIndex && step.condition(result))

        if (!prev) {
            return { name: 'vessel-results-id', params: { id: vesselId } }
        }

        return { name: prev.route_name, params: { id: vesselId } }
    }

    function isLastStep(vesselId, currentStep) {
        const allStep = [...STEPS.values()]
        const currentIndex = allStep.findIndex((each) => each.id === currentStep)

        const next = allStep
            .find((step, index) => index > currentIndex && step.condition(vesselResultState.result.result))

        return !next
    }

    return {
        Step,
        currentStepByRoute,
        nextStep,
        prevStep,
        isLastStep,
        STEPS: [...STEPS.values()].filter((each) => !each.hidden),
    }
}
